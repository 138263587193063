<!---
  Create a page that asks for a video file to be uploaded
  then use ffmpeg.wasm to convert the file to hls
  then upload all the files created to firebase storage
  then save the video info to the database
-->
<template>
  <div class="m-2">
    <h1>Upload</h1>
    <b-form-group label="Select MP4 File:" label-cols-sm="2">
      <b-form-file ref="formFile" accept="video/mp4" @change="handleFileUpload" :disabled="file != null"></b-form-file>
    </b-form-group>
    <b-table v-if="fileInfo" :items="[fileInfo]" />
    <b-alert variant="danger" :show="errorMsg != null" @click="errorMsg = null">
      Error: {{ errorMsg }}<br />
    </b-alert>
    <div v-if="passedAnalysis" class="mt-2">
      <b-input-group>
        <b-input-group-prepend is-text>Video Name</b-input-group-prepend>
        <b-input v-if="file" v-model="name" />
      </b-input-group>
      <div class="form-inline sub-mt-2 sub-mr-2" v-if="!startedUpload">
        <b-button variant="primary" v-if="file" @click="upload">Upload</b-button>
        <b-button variant="secondary" v-if="file" @click="cancel">Cancel</b-button>
      </div>
    </div>
    <div v-if="fileInfo && fileInfo.error">
      <b-button variant="secondary" @click="cancel">Try Another File</b-button>
    </div>
    <div v-if="progress" class="mt-2">
      <div>{{ progress }}</div>
      <b-progress v-if="completion" :value="completion" max="1.0"></b-progress>
    </div>
    <div v-if="link" class="mt-2">
      <b-alert variant="success" :show="true">
        Video uploaded successfully.
      </b-alert>
      <shareable :content="link" />
      <div class="form-inline sub-mt-2 sub-mr-2">
        <b-button variant="primary" @click="cancel()">Upload another Video</b-button>
        <b-button href="/vid/list">See Videos</b-button>
      </div>
    </div>
    <debug-group label="Debug" v-if="$debug.isOn">
      <b-row>
        <b-col>
          VideoID:
        </b-col>
        <b-col>
          <b-input v-model="videoId"></b-input>
        </b-col>
      </b-row>
      <b-button @click="makePublic(videoId)">Make Public Test</b-button>
    </debug-group>
  </div>
</template>

<script>
import { getLog } from '@/services/log';
let log = getLog('Upload');
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
import { db, storage } from '@/services/db';
import { callable } from '@/services/functions';
import { randomString } from '@/services/utils';
import { getUniqueLink, createLinkObject, getLinkUrl } from '@/services/links';
import { appConfig } from '@/services/appconfig';

export default {
  components: {
    Shareable: () => import('@/components/shareable.vue'),
  },
  data() {
    return {
      name: "Untitled",
      file: null,
      ffmpeg: null,
      progress: "",
      completion: 0,
      total_files_count: 1,
      completed_files_count: 0,
      lines: [],
      passedAnalysis: false,
      fileInfo: null,
      startedUpload: false,

      video: null,
      link: null,

      // debug
      videoId: null,
      errorMsg: null,
    }
  },
  async mounted() {
    this.init();
  },
  methods: {
    async init() {
      log.log('init');
      if (!window.crossOriginIsolated) {
        log.log("need crossOriginIsolated for SharedArrayBuffer (used by ffmpeg) to work");
        this.errorMsg = "Technical issue: Upload module needs crossOriginIsolated for SharedArrayBuffer (used by ffmpeg) to work. Please try reloading the page. Thanks!";
        this.file = {};
        return;
      }
      this.ffmpeg = createFFmpeg({
        corePath: '/static/ffmpeg-core.js',
        log: false,
        logger: ({ message }) => {
          this.lines.push(message);
          log.log(message);
        },
        progress: ({ ratio /* , time, duration */ }) => {
          this.progress = "Processing..."; //`ratio ${ratio} time ${time} duration ${duration}`;
          this.completion = ratio;
        },
      });
      await this.ffmpeg.load();
      log.log('ffmpeg loaded');
    },
    async makePublic(videoId) {
      log.log("makePublic", videoId);
      let makePublic = callable('vid-makePublic');
      let res = await makePublic({ videoId });
      log.log("makePublic res", res);
    },
    async handleFileUpload(event) {
      if (event.target.files.length == 0) {
        return;
      }
      this.file = event.target.files[0];
      this.name = this.file.name;
      // get filename
      let filename = this.file.name;
      log.log(filename);
      this.ffmpeg.FS('writeFile', filename, await fetchFile(this.file));
      let fileInfo = await this.analyzeFile(filename);
      this.fileInfo = fileInfo;
      log.log("fileInfo:", fileInfo);
      if (!fileInfo.error) {
        this.passedAnalysis = true;
        this.progress = "Ready to upload";
        this.completion = 0;
        log.log('file is compatible');
      }
      else {
        this.errorMsg = fileInfo.error;
        this.progress = "";
        log.log('file is not compatible');
      }
      await this.init();
    },
    async cancel() {
      log.log('cancel');
      this.file = null;
      this.errorMsg = null;
      this.passedAnalysis = false;
      this.fileInfo = null;
      this.$refs.formFile.reset();
      this.progress = "";
      this.completion = 0;
      this.startedUpload = false;
      this.video = null;
      this.link = null;
    },
    async upload() {
      let filename = this.file.name;
      this.startedUpload = true;
      this.ffmpeg.FS('writeFile', filename, await fetchFile(this.file));
      //await this.ffmpeg.run('-i', filename, '-profile:v', 'baseline', '-level', '3.0', '-start_number', '0', '-hls_time', '10', '-hls_list_size', '0', '-f', 'hls', 'output.m3u8');
      await this.ffmpeg.run('-i', filename, '-codec', 'copy', '-start_number', '0', '-hls_time', '10', '-hls_list_size', '0', '-f', 'hls', 'output.m3u8');
      log.log('ffmpeg complete');
      this.files = this.ffmpeg.FS('readdir', '/');
      // filter files that start with output
      this.files = this.files.filter(file => file.startsWith('output'))
      log.log(this.files);
      this.video = await this.uploadFiles(this.files);
      this.link = await this.createLink(this.video);
    },
    async analyzeFile(filename) {
      let res = {};
      this.lines = [];
      // check if the file is compatible with ffmpeg
      await this.ffmpeg.run('-v', 'info', '-i', filename);
      // check if the file has a video stream
      let vline = this.lines.filter(line => line.includes('Video: '));
      if (!vline.length) {
        res.error = "Video stream not detected.";
        return res;
      }
      // extract video encoding and bitrate from vline using a regex
      // example of vline variable: Stream #0:0[0x1](eng): Video: h264 (High) (avc1 / 0x31637661), yuvj420p(pc, bt709, progressive), 1080x1920 [SAR 1:1 DAR 9:16], 4856 kb/s, 30 fps, 30 tbr, 15360 tbn (default)
      let regex = /Video: (\w+) .+ (\d+) kb\/s/;
      let match = vline[0].match(regex);
      if (!match?.length) {
        res.error = "Video stream format not recognized, probaly not valid h264 video stream. Only h264 is supported.";
        return res;
      }
      else {
        res.videoEncoding = match[1];
        res.videoBitrate = match[2];
        if (res.videoEncoding != "h264") {
          res.error = "Video encoding not supported, only h264 is supported.";
          return res;
        }
      }
      let aline = this.lines.filter(line => line.includes('Audio: '));
      if (!aline.length) {
        res.warning = "Audio stream not detected.";
      }
      else {
        // extract audio encoding and bitrate from aline using a regex
        // example of aline variable: Stream #0:1[0x1](eng): Audio: aac (LC) (mp4a / 0x6134706D), 44100 Hz, stereo, fltp, 125 kb/s (default)
        regex = /Audio: (\w+) .+ (\d+) kb\/s/;
        match = aline[0].match(regex);
        if (!match?.length) {
          res.error = "Audio stream format not recognized, probaly not valid aac audio stream. Only aac is supported.";
          return res;
        }
        else {
          res.audioEncoding = match[1];
          res.audioBitrate = match[2];
          if (res.audioEncoding != "aac") {
            res.error = "Audio encoding not supported, only aac is supported.";
            return res;
          }
        }
      }
      this.lines = [];
      return res;
    },
    async uploadFiles(files) {
      let videoId = randomString(32);
      let pathPrefix = `s2/videos/${videoId}/`;
      this.progress = "Uploading...";
      this.total_files_count = files.length;
      this.completed_files_count = 0;
      // upload files to firebase storage
      await Promise.all(files.map(async (file) => {
        let path = pathPrefix + file;
        log.log("started uploading:", path);
        let data = this.ffmpeg.FS('readFile', file);
        await storage.ref(path).put(data);
        log.log("finished uploading:", path);
        this.completed_files_count++;
        this.completion = this.completed_files_count / this.total_files_count;
      }));
      await this.makePublic(videoId);
      this.progress = "Done";
      let url = `${appConfig.contentServerHost}/${pathPrefix}output.m3u8`;
      log.log(url);
      let data = {
        name: this.name,
        url: url,
        type: "video",
        ownerId: this.$store.account.uid,
        filename: this.file.name,
        created: new Date(),
      }
      db.collection('S2Videos').doc(videoId).set(data);
      data.id = videoId;
      return data;
    },
    async createLink(video) {
      log.log("createLink");
      let linkId = getUniqueLink("belong-", true);
      let link = createLinkObject(video, linkId, "default");
      return getLinkUrl(link.id);
    },
  }
}
</script>
